import OrganicAnimatedBox from '~/components/Molecules/Organic/AnimatedBox/AnimatedBox.vue'

export default {
  props: {
    image: {
      type: Object,
      required: true,
    },

    legend: {
      type: Object,
      default: () => {},
    },

    content: {
      type: String,
      required: true,
    },

    button: {
      type: Object,
      required: true,
    },

    isDesktop: {
      type: Boolean,
      default: true,
    },

    actions: {
      type: Boolean,
      default: false,
    },

    isTransitioning: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    OrganicAnimatedBox,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  mounted() {},

  computed: {
    classPosition() {
      return this.position
    },
  },

  methods: {},
}
