export default {
  data() {
    return {
      boxes: [],
    }
  },

  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  mounted() {
    this.loop()
  },

  methods: {
    createElement() {
      const bottom = 25
      const randomNumber = Math.floor(Math.random() * 2)
      const name = randomNumber === 1 ? 'organicLove' : 'organicThumbsUp'
      this.boxes.push({ bottom, name })
      setTimeout(() => {
        this.boxes.shift()
      }, 3000)
    },
    loop() {
      setInterval(() => {
        this.createElement()
      }, 150)
    },
  },
}
